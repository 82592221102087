<!--
 * @Description: 品类添加
 * @Author: kecraft
 * @Date: 2024-01-17 14:20:26
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 16:02:40
 * @FilePath: /impact-iotos-console/src/views/category/categoryAdd.vue
-->
<template>
  <div class="category-add">
    <myDialog @close="handleClose" @ok="handleOk" :title="title">
      <el-form :rules="rules" ref="formCategoryAdd" :model="form" label-position="left">
        <el-form-item label="节点名称：" prop="name" class="label-feather">
          <el-input v-model="form.name" class="width500" placeholder="请输入节点名称" />
        </el-form-item>
        <el-form-item label="自定义标识：" prop="customId" class="label-feather">
          <el-input v-model="form.customId" class="width500" placeholder="请输入品类ID" />
        </el-form-item>
        <el-form-item label="品类编码：" prop="customCode" class="label-feather">
          <el-input v-model="form.customCode" class="width500" placeholder="请输入品类编码" />
        </el-form-item>
        <el-form-item label="品类ID：" class="label-feather" v-if="isEdit">
          <el-input v-model="cid" class="width500" placeholder="请输入品类编码" :disabled="true" />
          <span class="copy-btn" @click="copyToClipboard(cid)">复制</span>
        </el-form-item>
      </el-form>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, defineExpose, defineEmits } from "vue";
import { ElMessage } from "element-plus";
import api from "@/api/api";
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["close", "childsClose"]);
const title = ref("");
const isEdit = ref(false);
const cid = ref(null);
const form = reactive({
  name: "", // 节点名称
  customId: "", // 品类ID
  customCode: "", // 品类code
  parentId: "",
  categoryLevel: "",
})
const rules = {
  name: [
    { required: true, message: "节点名称不能为空", trigger: "change" },
  ],
  customId: [
    { required: true, message: "品类ID不能为空", trigger: "change" },
  ],
  customCode: [
    { required: true, message: "品类编码不能为空", trigger: "change" },
  ],
}
const formCategoryAdd = ref(null);
const handleClose = () => {
  emit("close", false)
}
const handleOk = () => {
  formCategoryAdd.value.validate((valid) => {
    if (valid) {
      if (isEdit.value) {
        edit();
      } else {
        add();
      }
    } else {
      return false;
    }
  });
}

const add = () => {
  api.addCategory(form).then(res => {
    if (res.code === "0") {
      ElMessage.success("添加成功")
      if (form.categoryLevel === "FIRST") {
        emit("close", true)
      } else if (form.categoryLevel === "SECOND") {
        emit("twoClose", true)
      } else {
        emit("childsClose", true)
      }
    }
  })
}

const edit = () => {
  api.editCategory(cid.value, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("编辑成功")
      if (form.categoryLevel === "FIRST") {
        emit("close", true)
      } else if (form.categoryLevel === "SECOND") {
        emit("twoClose", true)
      } else {
        emit("childsClose", true)
      }
    }
  })
}


const init = (row) => {
  const { parentId, categoryLevel } = row;
  form.parentId = parentId;
  form.categoryLevel = categoryLevel;
  if (categoryLevel === "FIRST") {
    title.value = "添加一级节点"
  } else if (categoryLevel === "SECOND") {
    title.value = "添加二级节点"
  } else if (categoryLevel === "THIRD") {
    title.value = "添加三级节点"
  } else if (categoryLevel === "FOURTH") {
    title.value = "添加四级节点"
  } else if (categoryLevel === "FIFTH") {
    title.value = "添加五级节点"
  }
}

const editInit = (row) => {
  isEdit.value = true;
  const { parentId, categoryLevel, id, name, customCode, customId } = JSON.parse(JSON.stringify(row));
  if (categoryLevel === "FIRST") {
    title.value = "编辑一级节点"
  } else if (categoryLevel === "SECOND") {
    title.value = "编辑二级节点"
  } else if (categoryLevel === "THIRD") {
    title.value = "编辑三级节点"
  } else if (categoryLevel === "FOURTH") {
    title.value = "编辑四级节点"
  } else if (categoryLevel === "FIFTH") {
    title.value = "编辑五级节点"
  }
  cid.value = id;
  form.parentId = parentId;
  form.categoryLevel = categoryLevel;
  form.name = name;
  form.customCode = customCode;
  form.customId = customId;
}
defineExpose({ init, editInit });
</script>

<style lang="less" scoped>
.copy-btn {
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 20px;
  color: #367CC5;
  cursor: pointer;
}

.width500 {
  width: 500px;
}
</style>
<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 110px;
  }
}
</style>