<!--
 * @Description: 品类管理列表
 * @Author: kecraft
 * @Date: 2024-01-16 16:35:21
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 09:49:15
 * @FilePath: /impact-iotos-console/src/views/category/category.vue
-->
<template>
  <div class="category" id="home">
    <div class="category-left">
      <div class="left-title">
        <div>根节点列表</div>
        <div class="left-btns" v-if="!isDownload">
          <div @click="() => isImprot = true" v-domPreventReClick>
            <img src="@/assets/menu/import.png" alt="">
            <span>导入</span>
          </div>
          <div @click="handleDownload" v-domPreventReClick>
            <img src="@/assets/menu/upload.png" alt="">
            <span>导出</span>
          </div>
        </div>
      </div>
      <div v-if="isDownload" class="upload-box">
        <div class="btn-all">
          <el-checkbox v-model="isAll" label="全选" @change="handleSelectAllChange" />
        </div>
        <div class="download-btns">
          <div class="btn-normal" @click="() => isDownload = false" v-domPreventReClick>
            取消
          </div>
          <div class="btn-blue" @click="downloadCategory" v-domPreventReClick>
            导出
          </div>
        </div>
      </div>
      <div class="root-container" v-if="!isDownload">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <div class="root-box">
            <div class="root-item" @click="handleAddRoot">
              <img class="is-add" src="@/assets/icon/add_product.png">
            </div>
            <div v-for="(item, index) of rootList" :key="index"
              :class="activeIndex === item.id ? 'root-item-select' : 'root-item'" @click="handleRootSelect(item.id)">
              <div>
                {{ item.name }}
              </div>
              <div class="is-hover" v-if="activeIndex === item.id">
                <el-dropdown>
                  <el-icon>
                    <More />
                  </el-icon>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="handleAddSecond(item.id)">
                        <el-icon>
                          <Plus />
                        </el-icon>
                        添加下级
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleModel(item)" v-if="!item.hasChild">
                        <el-icon>
                          <EditPen />
                        </el-icon>
                        编辑功能定义
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleEditRoot(item)">
                        <el-icon>
                          <EditPen />
                        </el-icon>
                        编辑
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleBtnDel(item)">
                        <el-icon>
                          <Delete />
                        </el-icon>
                        删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="root-container" v-else>
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <div class="root-box">
            <div v-for="(item, index) of rootList" :key="index" class="root-item1" @click="handleRootChange(item)">
              <el-checkbox v-model="item.isSelect" class="is-checkbox" />
              <div>
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="category-right">
      <div class="search-container">
        <el-input v-model="query.keyword" class="input-item" placeholder="请输入品类名称" :prefix-icon="Search" />
        <div class="search-btn" @click="search" v-domPreventReClick>搜索</div>
        <div class="reset-btn" @click="reset" v-domPreventReClick>重置</div>
      </div>
      <div class="child-container">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <div class="child-item" v-for="(item, index) of secondList " :key="index">
            <div class="child-top">
              <div class="item-left">
                <img src="@/assets/newicon/up.png" class="has-img"
                  v-if="item.children && item.children.length > 0 && !item.isClose" @click="() => item.isClose = true">
                <img src="@/assets/newicon/down.png" class="has-img"
                  v-if="item.children && item.children.length > 0 && item.isClose" @click="() => item.isClose = false">
                <div class="has-img" v-if="!item.children || item.children.length === 0">
                </div>
                <div>{{ item.name }}</div>
              </div>
              <div class="item-right" v-if="item.children && item.children.length > 0">
                <el-dropdown>
                  <el-icon>
                    <More />
                  </el-icon>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="handleChildsAdd(item)">
                        <el-icon>
                          <Plus />
                        </el-icon>
                        添加下级
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleEditRoot(item)">
                        <el-icon>
                          <EditPen />
                        </el-icon>
                        编辑
                      </el-dropdown-item>
                      <el-dropdown-item @click="handleBtnDel(item)">
                        <el-icon>
                          <Delete />
                        </el-icon>
                        删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div class="item-right" v-else>
                <el-tooltip class="box-item" effect="dark" content="新增" placement="bottom">
                  <img src="@/assets/newicon/add.png" @click="handleChildsAdd(item)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="编辑" placement="bottom">
                  <img src="@/assets/newicon/edit.png" @click="handleEditRoot(item)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="编辑功能定义" placement="bottom">
                  <img src="@/assets/newicon/edit1.png" @click="handleModel(item)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="删除" placement="bottom">
                  <img src="@/assets/newicon/del.png" @click="handleBtnDel(item)" v-domPreventReClick>
                </el-tooltip>
              </div>
            </div>
            <div v-if="item.children && item.children.length > 0 && !item.isClose">
              <div class="child-bottom" v-for="(third, index1) of item.children" :key="index1">
                <div class="item-left">
                  <div class="has-img">
                  </div>
                  <div>{{ third.name }}</div>
                </div>
                <div class="item-right">
                  <el-tooltip class="box-item" effect="dark" content="编辑" placement="bottom">
                    <img src="@/assets/newicon/edit.png" @click="handleEditRoot(third)" v-domPreventReClick>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="编辑功能定义" placement="bottom">
                    <img src="@/assets/newicon/edit1.png" @click="handleModel(third)" v-domPreventReClick>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="删除" placement="bottom">
                    <img src="@/assets/newicon/del.png" @click="handleBtnDel(third)" v-domPreventReClick>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <categoryAdd v-if="isAdd" @close="handleAddClose" ref="categoryAddRef" @childsClose="handleChildsClose"
      @twoClose="handleTowClose" />
    <categoryModel v-if="isModel" ref="categortModelRef" @close="handleModelClose"
      @childsClose="handleModelChildsClose" />
    <categoryImport v-if="isImprot" @close="handleImportClose" />
  </div>
</template>

<script setup>
import FileSaver from 'file-saver'
import { Plus, EditPen, Delete, More, Search } from '@element-plus/icons-vue';
import categoryAdd from "./categoryAdd";
import categoryModel from "./categoryModel";
import categoryImport from './components/common/categoryImport';
import { convertToTree } from "@/hooks/common";
import api from "@/api/api";
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, ref, getCurrentInstance, nextTick, reactive } from 'vue';
// import { changeResize } from "@/utils/resize.js";
const activeIndex = ref(null);
const isAll = ref(false);
const isImprot = ref(false);
const isAdd = ref(false);
const isModel = ref(false);
const isDownload = ref(false);
const activeName = ref(null);
const allTree = ref(null); // 整棵树
const rootList = ref([]); // 根列表
const secondList = ref([]); // 二级列表
const query = reactive({
  keyword: "",
});
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  getRoots()
})
const handleRootSelect = (v) => {
  if (activeIndex.value === v) return;
  activeIndex.value = v;
  getChilds();
}
const getRoots = () => {
  rootList.value = [];
  secondList.value = [];
  activeIndex.value = "";
  activeName.value = "";
  api.getDefaultCategory().then(res => {
    if (res.code === "0") {
      rootList.value = res.res.data.rootList;
      if (rootList.value.length > 0) {
        const first = rootList.value[0];
        activeIndex.value = first.id;
        activeName.value = first.name;
        getChilds()
      }
    }
  })
}

const getChilds = () => {
  secondList.value = []
  api.getCategoryByCid(activeIndex.value,
    {
      name: query.keyword
    }).then(res => {
      if (res.code === "0") {
        const list = res.res.data || []
        const tree = convertToTree(list)
        allTree.value = tree;
        if (tree.length > 0) {
          const { children } = tree[0];
          secondList.value = children;
        }
      }
    })
}

const handleRootChange = (item) => {
  const { id, name } = item;
  if (id === activeIndex.value) return;
  activeIndex.value = id;
  activeName.value = name;
  getChilds();
}

const search = () => {
  getChilds();
}

const reset = () => {
  query.keyword = "";
  getChilds();
}
/**
 * 添加根节点
 */
const handleAddRoot = () => {
  const obj = {
    parentId: "",
    categoryLevel: "FIRST"
  }
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.categoryAddRef.init(obj);
  });
}

/**
 * 二级品类新增
 * @param {*} parentId 
 */
const handleAddSecond = (parentId) => {
  const obj = {
    parentId,
    categoryLevel: "SECOND"
  }
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.categoryAddRef.init(obj);
  });
}

const handleChildsAdd = (row) => {
  const list = ["FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH"]
  const { id, categoryLevel } = row;
  let level = null;
  list.forEach((item, index) => {
    if (item === categoryLevel && index !== list.length - 1) {
      level = list[index + 1];
    }
  })
  if (!level) return;
  const obj = {
    parentId: id,
    categoryLevel: level
  }
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.categoryAddRef.init(obj);
  });
}

/**
 * 根节点 - 需要刷新左侧的根节点列表
 * @param {*} v 
 */
const handleAddClose = (v) => {
  isAdd.value = false;
  if (v) {
    getRoots();
  }
}

/**
 * 非根节点 - 需要刷新右侧的子品类
 * @param {*} v 
 */
const handleChildsClose = (v) => {
  isAdd.value = false;
  if (v) {
    getChilds();
  }
}

const handleTowClose = (v) => {
  isAdd.value = false;
  if (v) {
    rootList.value = [];
    secondList.value = [];
    api.getDefaultCategory().then(res => {
      if (res.code === "0") {
        rootList.value = res.res.data.rootList;
        if (rootList.value.length > 0) {
          getChilds()
        }
      }
    })
  }
}
const handleModelClose = (v) => {
  isModel.value = false;
  if (v) {
    getRoots();
  }
}

const handleModelChildsClose = (v) => {
  isModel.value = false;
  if (v) {
    getChilds();
  }
}

const handleEditRoot = (row) => {
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.categoryAddRef.editInit(row);
  });
}

const handleModel = (row) => {
  const obj = JSON.parse(JSON.stringify(row))
  const tree = allTree.value;
  let result = getParentId(tree, obj)
  if (result && result.length > 0) {
    result.reverse();
    result.push(obj.name);
  } else {
    result = [obj.name]
  }
  obj.models = result.join("/")
  isModel.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.categortModelRef.init(obj);
  });
}

function getParentId(list, item) {
  for (let i in list) {
    if (list[i].id === item.parentId) {
      return [list[i].name]
    }
    if (list[i].children != null) {
      let node = getParentId(list[i].children, item);
      if (node !== undefined) {
        return node.concat(list[i].name)
      }
    }
  }
}
const handleBtnDel = (row) => {
  const { id, categoryLevel } = row;
  if (!id) return;
  if ((categoryLevel == "FIRST" && secondList.value && secondList.value.length > 0) || (categoryLevel != "FIRST" && row.children && row.children.length > 0)) {
    ElMessageBox.confirm("该品类存在子品类，将删除该品类及所有子品类，是否确定？", "提示", {
      type: "warning",
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
      api.delCategory(id).then(res => {
        if (res.code === "0") {
          ElMessage.success("删除成功！");
          if (categoryLevel === "FIRST") {
            getRoots();
          } else if (categoryLevel === "SECOND") {
            rootList.value = [];
            secondList.value = [];
            api.getDefaultCategory().then(res => {
              if (res.code === "0") {
                rootList.value = res.res.data.rootList;
                if (rootList.value.length > 0) {
                  getChilds()
                }
              }
            })
          } else {
            getChilds();
          }
        }
      })
    })
  } else {
    ElMessageBox.confirm("确定删除该品类吗？", "提示", {
      type: "warning",
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
      api.delCategory(id).then(res => {
        if (res.code === "0") {
          ElMessage.success("删除成功！");
          if (categoryLevel === "FIRST") {
            getRoots();
          } else if (categoryLevel === "SECOND") {
            rootList.value = [];
            secondList.value = [];
            api.getDefaultCategory().then(res => {
              if (res.code === "0") {
                rootList.value = res.res.data.rootList;
                if (rootList.value.length > 0) {
                  getChilds()
                }
              }
            })
          } else {
            getChilds();
          }
        }
      })
    })
  }

}

const handleDownload = () => {
  rootList.value.forEach(item => {
    item.isSelect = false;
  })
  isAll.value = false;
  isDownload.value = true;
}

const handleSelectAllChange = () => {
  rootList.value.forEach(item => {
    item.isSelect = isAll.value;
  })
}

/**
 * 导出
 */
const downloadCategory = () => {
  const rootIds = []
  rootList.value.forEach(item => {
    if (item.isSelect) {
      rootIds.push(item.id)
    }
  });
  if (rootIds.length === 0) {
    ElMessage.error("请至少选择一个品类！")
    return;
  }
  api.exprotCategory({
    rootIds
  }).then(res => {
    if (res.code === "0") {
      const json = res.res.data;
      const blob = new Blob([JSON.stringify(json)], { type: "application/json;charset=utf-8" });
      FileSaver.saveAs(blob, "品类" + '.json')
      ElMessage.success("导出成功!")
    }
  })
}

const handleImportClose = (v) => {
  isImprot.value = false;
  if (v) {
    getRoots();
  }
}
</script>

<style lang="less" scoped>
.btn-all {
  margin-left: 30px;
}

.btn-blue {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #367CC5;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.btn-normal {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.upload-box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  justify-content: space-between;
  margin-bottom: 20px;

  .download-btns {
    display: flex;
    align-items: center;

  }
}

.category {
  background: #FAFAFB;
  height: calc(100vh - 66px);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 10px;

  .category-left {
    width: 417px;
    height: 100%;
    background: #ffffff;
    border-radius: 15px;
    margin-right: 13px;
    box-sizing: border-box;

    .left-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      justify-content: space-between;
      margin-bottom: 26px;
      padding: 16px 35px;
      padding-bottom: 0;
      box-sizing: border-box;

      .left-btns {
        display: flex;
        align-items: center;
        cursor: pointer;

        &>div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: lighter;

          img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
          }
        }

        &>div:last-child {
          margin-left: 14px;
        }
      }
    }

    .root-container {
      height: 90%;

      .root-box {
        box-sizing: border-box;
        padding: 16px 35px;
        padding-top: 0;
      }
    }

    .root-item {
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      border: 1px solid #EEEEEE;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #333333;
      cursor: pointer;

      .is-hover {
        margin-left: 10px;
        display: none;
      }

      .is-add {
        width: 23px;
        height: 23px;
      }
    }

    .root-item1 {
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      padding: 0 28px;
      border: 1px solid #EEEEEE;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      // justify-content: center;
      font-size: 18px;
      color: #333333;
      cursor: pointer;

      .is-checkbox {
        margin-right: 86px;
      }

      .is-hover {
        margin-left: 10px;
        display: none;
      }

      .is-add {
        width: 23px;
        height: 23px;
      }
    }

    .root-item:hover {
      border: 1px solid #367CC5;
      background: #EBEFF6;

      .is-hover {
        display: block;
      }
    }

    .root-item-select {
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #333333;
      cursor: pointer;
      border: 1px solid #367CC5;
      background: #EBEFF6;
      position: relative;

      .is-hover {
        margin-left: 10px;
      }
    }
  }

  .category-right {
    width: calc(100% - 430px);
    height: 100%;
    border-radius: 15px;

    .child-container {
      width: 100%;
      height: 90%;
      margin-top: 15px;

      .child-item {
        width: 100%;
        background: #ffffff;
        margin-bottom: 10px;
        border-radius: 15px;

        .child-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 22px 42px;

          .item-left {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333333;

            .has-img {
              width: 10px;
              height: 6px;
              margin-right: 23px;
            }
          }

          .item-right {
            display: flex;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
              margin-left: 25px;
            }
          }
        }

        .child-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          // padding: 22px 42px;
          padding-left: 80px;
          padding-right: 42px;
          padding-bottom: 24px;

          .item-left {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333333;

            .has-img {
              width: 10px;
              height: 6px;
              margin-right: 23px;
            }
          }

          .item-right {
            display: flex;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
              margin-left: 25px;
            }
          }
        }
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0;

      .input-item {
        width: 260px;
        margin-right: 40px;
      }
    }

    .search-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      background: #367CC5;
      font-weight: lighter;
      // border: 1px solid #015ee0;
      font-size: 14px;
      color: #FFFFFF;
      font-size: 16px;
    }

    .search-btn:hover,
    .search-btn:active {
      border: 1px solid #367CC5;
      background: #61A4E9;
      // color: #367CC5;
    }

    .reset-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      font-weight: lighter;
      border: 1px solid #AFB9CB;
      color: #333333;
      font-size: 16px;
    }
  }
}
</style>