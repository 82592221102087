<!--
 * @Description: 品类 - 功能定义
 * @Author: kecraft
 * @Date: 2024-01-17 14:29:20
 * @LastEditors: kecraft
 * @LastEditTime: 2024-04-29 17:18:37
 * @FilePath: /impact-iotos-console/src/views/category/categoryModel.vue
-->
<template>
  <div class="category-model">
    <myDialog @close="handleClose" @ok="handleOk" title="编辑功能定义" width="700px">
      <el-form ref="formCommandAdd" :model="form" label-position="top">
        <el-form-item label="产品品类：" prop="name">
          <el-input v-model="form.name" style="width: 612px;" placeholder="请选择" :disabled="true" />
        </el-form-item>
        <el-form-item label="功能定义内容：" prop="model">
          <el-input v-model="form.model" :rows="10" type="textarea" placeholder="请输入功能定义内容" />
        </el-form-item>
      </el-form>
      <div>提示：请到“产品详情->功能定义->查看功能定义“获取内容。</div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { js_beautify } from 'js-beautify';
import { ElMessage } from "element-plus";
import api from "@/api/api";
import { reactive, defineExpose, ref, defineEmits } from "vue";
const emit = defineEmits(["close", "childsClose"]);
const form = reactive({
  name: "",
  model: ""
})
const isRoot = ref(false);
const cid = ref(null);
const handleOk = () => {
  const { model } = form
  let isTrue = true;
  if (model) {
    try {
      JSON.parse(model);
    } catch (e) {
      ElMessage.error("功能定义必须为JSON格式")
      return false;
    }
    const json = JSON.parse(model);
    if (typeof json !== "object") {
      ElMessage.error("功能定义必须为JSON格式")
      return false;
    }
    const { cmds, params } = json;
    if (cmds) {
      if (!Array.isArray(cmds)) {
        isTrue = false;
      }
    }
    if (params) {
      if (!Array.isArray(params)) {
        isTrue = false;
      }
    }
    if (!params && !cmds) {
      isTrue = false;
    }
  } else {
    // ElMessage.error("功能定义不能为空")
    // isTrue = false
  }
  if (isTrue) {
    if (model) {
      const json = JSON.parse(model);
      const { cmds, params } = json;
      api.editCategoryModelByCid(cid.value, {
        params: params || [],
        cmds: cmds || [],
      }).then(res => {
        if (res.code === "0") {
          ElMessage.success("编辑成功！")
          if (isRoot.value) {
            emit("close", true)
          } else {
            emit("childsClose", true)
          }
        }
      })
    } else {
      api.editCategoryModelByCid(cid.value, {
        // params: [],
        // cmds: [],
      }).then(res => {
        if (res.code === "0") {
          ElMessage.success("编辑成功！")
          if (isRoot.value) {
            emit("close", true)
          } else {
            emit("childsClose", true)
          }
        }
      })
    }

  }
}
const handleClose = () => {
  if (isRoot.value) {
    emit("close", false)
  } else {
    emit("childsClose", false)
  }
}

const init = (row) => {
  const { id, models, categoryLevel } = row;
  if (categoryLevel === "FIRST") {
    isRoot.value = true;
  }
  form.name = models;
  cid.value = id;
  getModelByCid();
}

const getModelByCid = () => {
  api.getCategoryModelByCid(cid.value).then(res => {
    if (res.code === "0") {
      const { model } = res.res.data;
      const newModel = js_beautify(model);
      form.model = newModel;
    }
  })
}
defineExpose({ init });
</script>