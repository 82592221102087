<!--
 * @Description: 导入品类
 * @Author: kecraft
 * @Date: 2024-01-18 14:15:51
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:23:11
 * @FilePath: /impact-iotos-console/src/views/category/components/common/categoryImport.vue
-->
<template>
  <div class="category-improt">
    <myDialog title="导入品类" width="700px" :footVisible="false">
      <div class="width500">
        <p>点击“导入”按钮将选择文件进行上传。</p>
        <p>导入品类将删除现有品类结构，确定导入？</p>
        <div class="dialog__footer">
          <span @click="handleClose()" v-domPreventReClick>取消</span>
          <el-upload action="" class="upload-demo" ref="upload" accept=".json" :limit="1" :show-file-list="false"
            :on-exceed="handleExceed" :http-request="changeUpload">
            <template #trigger>
              <span class="is_noclick" @click="handleExceed()" v-domPreventReClick>导入品类</span>
            </template>
          </el-upload>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ElMessage } from "element-plus";
import { defineEmits, onMounted, getCurrentInstance } from "vue";
import api from "@/api/api"
const emit = defineEmits(["close"]);
const handleClose = () => {
  emit("close", false)
}
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const changeUpload = (e) => {
  const file = e.file
  const size = file.size / (1024 * 1024); //文件大小
  if (size > 50) {
    return ElMessage.error("文件大小不能超过50M！");
  }
  let fd = new FormData();
  fd.append("file", file);
  api.improtCategory(fd).then(res => {
    if (res.code === "0") {
      ElMessage.success("导入成功！")
      emit("close", true)
    }
  })

}
const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
}
</script>

<style lang="less" scoped>
.dialog__footer {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  span {
    width: 96px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    cursor: pointer;
  }

  &>span:nth-child(1) {
    color: #333333;
    border: 1px solid #B7BBC0;
    margin-right: 17px;
  }

  .is_noclick {
    background: #367CC5;
    color: #FFFFFF;
  }

}

.width500 {
  width: 500px;
}

p {
  line-height: 24px;
}
</style>